"use client";

import { ApolloLink } from "@apollo/client";
import {
  ApolloClient,
  ApolloNextAppProvider,
  SSRMultipartLink
} from "@apollo/experimental-nextjs-app-support";

import inMemoryCache from "./cache";
import apolloClientLink from "./link";

const makeClient = () => {
  return new ApolloClient({
    cache: inMemoryCache,
    connectToDevTools: process.env.ENVIRONMENT !== "production",
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true
            }),
            apolloClientLink
          ])
        : apolloClientLink
  });
};

export const ApolloProvider = ({ children }: React.PropsWithChildren) => {
  return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>;
};

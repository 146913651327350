"use client";

import { useMemo } from "react";
import { usePathname } from "next/navigation";
import { Chip, Stack, Typography, useMediaQuery } from "@mui/material";
import { Breakpoint, useTheme } from "@mui/material/styles";

import { envIsProd } from "common/util/envHelpers";
import { titleize } from "common/util/helpers";
import { cssMediaQuery } from "common/util/style";

export const Breakpoints = () => {
  const theme = useTheme();
  const breakpoints = useMemo(() => {
    const { breakpoints } = theme;
    return breakpoints;
  }, [theme]);

  return (
    <Stack direction="row" gap={1}>
      {breakpoints.keys?.map((b: Breakpoint) => (
        <Chip
          label={b}
          size="small"
          key={b}
          sx={({ palette }) => ({
            minWidth: 40,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // eslint-disable-next-line react-hooks/rules-of-hooks
            ...(useMediaQuery(theme.breakpoints.only(b))
              ? {
                  background: palette.brand.main,
                  color: palette.brand.contrastText,
                  fontWeight: 700
                }
              : { background: palette.common.white })
          })}
        />
      ))}
    </Stack>
  );
};

export const EnvWarningBanner = () => {
  const pathname = usePathname();
  const title = null;

  if (!process.env.SHOW_STAGING_BANNER) {
    return null;
  }

  // add exception for portals that are in "production"
  if (envIsProd() && pathname?.match(/^\/portals\/stories/)) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={({ palette }) => ({
        background: palette.grey[300],
        minHeight: "36px"
      })}
      px={2}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Chip
          label={titleize(process.env.ENVIRONMENT ?? "")}
          variant="outlined"
          size="small"
          sx={({ palette }) => ({
            background: palette.warning.light,
            fontWeight: 700,
            borderColor: palette.warning.main
          })}
        />
        <Stack
          direction="row"
          gap="5px"
          alignItems="center"
          sx={{
            [cssMediaQuery({ maxOrMin: "max" })]: {
              display: "none"
            }
          }}
        >
          <Typography variant="body2">
            <Typography component="span" fontWeight={700}>
              Please do not distribute{" "}
            </Typography>
            — pages may contain out of date data.
          </Typography>
          {title && (
            <>
              <Typography component="span" variant="body3">
                {" "}
                |{" "}
              </Typography>
              <Typography component="span" variant="body3">
                {title}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Breakpoints />
    </Stack>
  );
};

import { Link, Typography } from "@mui/material";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";

import {
  BrandBarAgenciesIcon,
  BrandBarContactIcon,
  BrandBarNewsIcon,
  BrandBarTopicsIcon
} from "./icons";

interface ExternalSiteButtonProps {
  href: string;
  icon: JSX.Element;
  title: string;
}

export const ExternalSiteButton = ({ href, icon, title }: ExternalSiteButtonProps) => {
  return (
    <Link
      onClick={() =>
        sendGaNavigationEvent({
          category: "Headers",
          action: "State Header Nav Item Click",
          label: title
        })
      }
      href={href}
      sx={{
        color: "rgba(255, 255, 255, 0.8)",
        fontSize: "16px",
        letterSpacing: ".3px",
        padding: "8px 6px",
        borderBottom: "2px solid transparent",
        "&:focus, &:hover": {
          color: "rgba(255, 255, 255, .9)",
          borderBottom: "2px solid hsla(0,0%,100%,.25);"
        },
        display: "flex",
        alignItems: "center"
      }}
      underline="none"
    >
      {icon}
      <Typography
        component="span"
        sx={{
          display: "block",
          fontSize: "inherit",
          my: "auto",
          fontWeight: 800,
          paddingLeft: "3px",
          paddingRight: "3px"
        }}
      >
        {" "}
        {title}{" "}
      </Typography>
    </Link>
  );
};

export const AgenciesBrandButton = () => (
  <ExternalSiteButton
    href="https://delaware.gov/state-directory"
    icon={BrandBarAgenciesIcon()}
    title="Agencies"
  />
);
export const NewsBrandButton = () => (
  <ExternalSiteButton href="https://news.delaware.gov/" icon={BrandBarNewsIcon()} title="News" />
);
export const TopicsBrandButton = () => (
  <ExternalSiteButton
    href="https://delaware.gov/guides/"
    icon={BrandBarTopicsIcon()}
    title="Topics"
  />
);
export const ContactBrandButton = () => (
  <ExternalSiteButton
    href="https://delaware.gov/contact/"
    icon={BrandBarContactIcon()}
    title="Contact"
  />
);

"use client";

import { useState } from "react";
import Image from "next/legacy/image";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Link, LinkProps, Typography } from "@mui/material";
import dhssLogoWithoutTitle from "public/images/dhss-logo-without-title.svg";

import { NAV_MARGIN, SITE_FOOTER_HEIGHT } from "layout/configuration";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import { UnstyledList } from "common/components/lists/UnstyledList";
import FooterToggle from "./FooterToggle";
import StateFooter from "./StateFooter";

const SiteFooter = () => {
  const [footerActive, setFooterActive] = useState(false);
  const linkProps: Partial<LinkProps> = {
    variant: "body1",
    underline: "hover",
    color: "text.secondary",
    sx: { fontWeight: 600 }
  };
  const isMobile = useIsMobile();
  const footerIconSx = {
    color: "white",
    fontWeight: 700,
    "&:hover": {
      fontWeight: 500,
      color: "component.lightBackground"
    }
  };
  return (
    <Box sx={{ position: "relative", width: "100%" }} component="footer">
      <Box
        sx={{
          alignItems: "center",
          borderTop: "4px solid",
          borderTopColor: "brand.main",
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          justifyContent: "space-between",
          minHeight: SITE_FOOTER_HEIGHT,
          pb: isMobile ? 2 : 0,
          px: NAV_MARGIN
        }}
      >
        <Box>
          <Link
            href="//dhss.delaware.gov/dhss/"
            underline="none"
            sx={{ display: "flex", gap: 2, alignItems: "center" }}
            color="brandSecondary.main"
            target="_blank"
          >
            <Image
              style={{ display: "flex", alignItems: "center" }}
              height={60}
              width={60}
              src={dhssLogoWithoutTitle}
              alt="Department of Health and Social Services, DHSS logo — circular graphic with faces of different styles"
            />
            <Typography
              variant="body1"
              color="brandSecondary.main"
              component="span"
              fontWeight={800}
              lineHeight={1.2}
            >
              Delaware Health and <br />
              Social Services
            </Typography>
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: isMobile ? 1 : 6,
            pr: { xs: 0, md: "80px" }
          }}
        >
          <Box sx={{ display: "flex", gap: 6, alignSelf: "stretch" }}>
            <UnstyledList
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                my: 2,
                flexDirection: isMobile ? "column" : "row"
              }}
            >
              <Box component="li">
                <Link {...linkProps} href="/about">
                  About
                </Link>
              </Box>
              <Box component="li">
                <Link {...linkProps} href="/data-dictionary">
                  Data Downloads
                </Link>
              </Box>
              <Box
                sx={({ palette }) => ({
                  mx: 2,
                  borderRight: `1px solid ${palette.borders.main}`,
                  width: "1px",
                  alignSelf: "stretch",
                  display: isMobile ? "none" : "block"
                })}
                component="li"
              />
              <Box component="li">
                <Link {...linkProps} href="/faq">
                  FAQ
                </Link>
              </Box>
              <Box component="li">
                <Link {...linkProps} href="/news">
                  What&apos;s Coming
                </Link>
              </Box>
              <Box component="li">
                <Link {...linkProps} href="/feedback">
                  Feedback
                </Link>
              </Box>
              <Box component="li">
                <Link {...linkProps} href="/admin">
                  Admin
                </Link>
              </Box>
            </UnstyledList>
          </Box>
        </Box>
        <Box
          sx={{
            height: "8px",
            backgroundColor: "brand.main",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 5001
          }}
        />
        <FooterToggle
          icon={footerActive ? <RemoveIcon sx={footerIconSx} /> : <AddIcon sx={footerIconSx} />}
          onClick={() => setFooterActive(!footerActive)}
          sx={{
            ...(isMobile
              ? {
                  position: "absolute",
                  top: 0,
                  right: "20px",
                  transform: "translateY(calc(-100% - 4px))"
                }
              : {
                  zIndex: 5002,
                  position: "absolute",
                  right: "20px",
                  bottom: "8px"
                })
          }}
        />
      </Box>
      <StateFooter active={footerActive} handleToggle={() => setFooterActive(!footerActive)} />
    </Box>
  );
};

export default SiteFooter;

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import isString from "lodash/isString";
import { InMemoryCacheConfig } from "@apollo/client";

interface StatIdentifierOverlay {
  name?: string;
  unitLabel?: string;
  description?: string;
}

const placesDesc = "Adults over 18";
const statIdentifierOverrides: Record<string, StatIdentifierOverlay> = {
  CANCER_CDC_PLACES_CRUDE_RATE: {
    unitLabel: placesDesc
  },
  COPD_CDC_PLACES_CRUDE_RATE: {
    unitLabel: placesDesc
  },
  CHD_CDC_PLACES_CRUDE_RATE: {
    unitLabel: placesDesc
  },
  DIABETES_CDC_PLACES_CRUDE_RATE: {
    unitLabel: placesDesc
  },
  STROKE_CDC_PLACES_CRUDE_RATE: {
    unitLabel: placesDesc
  },
  CHOLSCREEN_CDC_PLACES_CRUDE_RATE: {
    unitLabel: placesDesc
  },
  MAMMOUSE_CDC_PLACES_CRUDE_RATE: {
    unitLabel: "Women aged 50-75"
  },
  CERVICAL_CDC_PLACES_CRUDE_RATE: {
    unitLabel: "Women aged 21-65"
  },
  COLON_SCREEN_CDC_PLACES_CRUDE_RATE: {
    unitLabel: "Adults aged 50-75"
  },
  COREM_CDC_PLACES_CRUDE_RATE: {
    unitLabel: "Men aged 65+"
  },
  COREW_CDC_PLACES_CRUDE_RATE: {
    unitLabel: "Women aged 65+"
  },

  POPULATION_DENSITY: {
    unitLabel: "People per square mile"
  },
  POPULATION_TOTAL_COUNT: {
    unitLabel: "People"
  },
  STATE_AREA_DEPRIVATION_INDEX: {
    unitLabel: "on a scale of 1-10"
  },
  MEDIAN_HOUSEHOLD_INCOME_DOLLARS: {
    unitLabel: "Annual income"
  },
  POVERTY_RATE: {
    unitLabel: "of population"
    // description: `The poverty line is calculated by finding the total cost of all the essential resources that an average human adult consumes in one year.`
  },
  COVID19_CUMULATIVELY_COMPLETED_VACCINATIONS_CRUDE_RATE: {
    name: "COVID Cumulative Completed Vaccinations"
  },
  COVID19_MORTALITY_CRUDE_RATE: {
    name: "COVID Deaths"
  },
  COVID19_PREVALENCE_CRUDE_RATE: {
    name: "COVID Cumulative Number of Positive Cases"
  }
};

interface AttributionOverlay {
  name?: string;
  dates?: string;
}

const attributionOverrides: Record<string, AttributionOverlay> = {
  DECENNIAL_CENSUS: {
    name: "US Decennial Census",
    dates: ""
  },
  ACS: {
    dates: "2010-2019"
  }
};

export const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    MhcAttribution: {
      fields: {
        name: {
          read(value, { readField }) {
            const id = readField("id");
            const override = isString(id) ? attributionOverrides[id] : undefined;
            return override?.name ?? value;
          }
        },
        dates: {
          read(value, { readField }) {
            const id = readField("id");
            const override = isString(id) ? attributionOverrides[id] : undefined;
            return override?.dates ?? value;
          }
        }
      }
    },
    MhcLocation: {
      keyFields: ["id"]
    },
    MhcStatIdentifier: {
      fields: {
        name: {
          read(value, { readField }) {
            const id = readField("id");
            const override = isString(id) ? statIdentifierOverrides[id] : undefined;
            return override?.name || value;
          }
        },
        addendum: {
          read(value) {
            // percentage isn't useful to display since we know it already
            // from the unit
            return value === "percentage" ? null : value;
          }
        },
        unit: {
          read(value) {
            return value ? value : "count";
          }
        },
        unitLabel: {
          read(value, { readField }) {
            const id = readField("id");
            const override = isString(id) ? statIdentifierOverrides[id] : undefined;
            const result = override?.unitLabel || value;
            if (result === "Percentage") return null;
            if (result === "percent") return null;
            if (result === "Repondents Affirming Question *") return "Repondents";
            return result;
          }
        },
        description: {
          read(value, { readField }) {
            const id = readField("id");
            const override = isString(id) ? statIdentifierOverrides[id] : undefined;
            return override?.description || value;
          }
        }
      }
    },
    MhcStratificationGroup: {
      keyFields: (obj: any, { readField }) => {
        const ids: string[] = [];
        (readField("statIdentifiers") as any).forEach((x: any) => {
          ids.push(readField("id", x) ?? "");
        });
        const key = `[${obj.__typename ?? ""}:${obj.id ?? ""}]::${obj.name}:${ids.join("-") ?? ""}`;
        return key;
      }
    }
  }
};

"use client";

import { useCallback, useState } from "react";
import { Button, Modal, Paper, Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";

export const POLICY_ACCEPTED_COOKIE_ID = "policy_accepted";

export const AcceptableUsePolicyModal = () => {
  const accepted = Cookies.get(POLICY_ACCEPTED_COOKIE_ID) === "true";
  const [open, setOpen] = useState<boolean>(!accepted);

  const acceptOnClick = useCallback(() => {
    Cookies.set(POLICY_ACCEPTED_COOKIE_ID, "true");
    setOpen(false);
  }, []);

  return (
    <Modal open={open} onClose={setOpen}>
      <Paper
        elevation={10}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "max-content",
          maxWidth: "95vw",
          bgcolor: "background.paper",
          border: "0px transparent #000",
          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
        }}
      >
        <Stack gap={4} maxWidth="800px" p={4}>
          <Stack gap={1}>
            <Typography component="h1" variant="h1" color="brand.main">
              Acceptable Use Policy
            </Typography>
            <Typography variant="h5">
              By using this website you agree to our Acceptable Use Policy.
            </Typography>
          </Stack>
          <Stack gap={2}>
            <Typography variant="body1">
              The Delaware Environmental Public Health Tracking Network (My Healthy Community)
              strives to comply at all times with the rules governing the use or disclosure of
              individual health information for research purposes. These rules include the federal
              regulations under HIPAA for the protection of people&apos;s privacy rights. Your use
              of these data must respect the privacy and confidentiality of individuals, and as
              such, you must not attempt to identify any individuals whose records were used in the
              calculation of the measures and results.
            </Typography>
            <Typography variant="body1">
              As a user of this site, you hereby acknowledge that you will not make any attempt to
              identify individual health records, and any attempt to do so constitutes a violation
              of the terms of this agreement.
            </Typography>
          </Stack>
          <Stack direction="row" gap={4}>
            <Button
              sx={{ textTransform: "capitalize" }}
              color="brand"
              variant="contained"
              onClick={acceptOnClick}
            >
              I Agree
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
};

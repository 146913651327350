import { Container, ContainerProps } from "@mui/material";

const defaultSx = { ml: 0 };
export const SiteContentContainer: React.FC<ContainerProps> = ({
  maxWidth = "xl",
  sx,
  ...props
}) => {
  const mySx = sx ? { ...sx, ...defaultSx, flex: "1 1 100%" } : defaultSx;
  return <Container maxWidth={maxWidth} sx={mySx} {...props} />;
};

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { FuzzySearch } from "./FuzzySearch/FuzzySearch";

export const SiteTopNavSearch = () => {
  const { breakpoints } = useTheme();

  return (
    <Box
      component="div"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignContent: "end",
        width: "350px",
        my: "8px",
        mx: 0,
        [breakpoints.down("md")]: {
          ml: "auto",
          width: "250px"
        }
      }}
    >
      <FuzzySearch
        modalProps={{ width: "50vw", maxWidth: "500px" }}
        uiLocation="Site Top Nav Search"
      />
    </Box>
  );
};

import { useMemo } from "react";
import { useParams } from "next/navigation";
import { Box } from "@mui/material";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { TopNavLink } from "./SiteTopNavLink";

export const SiteTopNavLinks = () => {
  const params = useParams();
  const selectedLocation = useMemo(() => {
    const locationId = params?.["locationId"];
    if (locationId !== undefined && typeof locationId === "string") {
      return locationId;
    }
    return "state";
  }, [params]);

  if (useIsMobile()) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch"
      }}
    >
      <TopNavLink href="/home" title="Home" />
      <TopNavLink href={`/topics/all/${selectedLocation}`} title="All Topics" />
      <TopNavLink href="/data-dictionary" title="Data Downloads" />
    </Box>
  );
};

import Image from "next/legacy/image";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, ButtonProps, Container, Grid, Link, LinkProps, Slide } from "@mui/material";

import { visuallyHidden } from "@mui/utils";

import styles from "./StateFooter.module.css";

interface Props {
  active: boolean;
  handleToggle: ButtonProps["onClick"];
}

const StateFooter: React.FC<Props> = ({ active = false }) => {
  const { footerWrapper, footerHeading, hr } = styles;
  const linkProps: Partial<LinkProps> = {
    color: "inherit",
    underline: "hover",

    sx: { opacity: 0.85, transition: ".125s all ease-in-out", "&:hover": { opacity: 1 } }
  };
  const listStyle = { color: "white", listStyleType: "none", m: 0, p: 0 };

  return (
    <Slide direction="up" in={active} mountOnEnter unmountOnExit>
      <Box id="footer" className={footerWrapper}>
        <Container>
          <h1 className={footerHeading}>Delaware&apos;s Government</h1>
          <hr className={hr} />
          <Grid container spacing={4}>
            <Grid item xs={6} md={3}>
              <Box component="ul" sx={listStyle}>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//governor.delaware.gov">
                    Delaware&apos;s Governor
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/agencylist_alpha">
                    State Agencies
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/yourgovernment">
                    Elected Officials
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//legis.delaware.gov/">
                    General Assembly
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//courts.delaware.gov/">
                    Delaware Courts
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//dhr.delaware.gov/personnel/employee-resources.shtml">
                    State Employees
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/municipalities">
                    Cities &amp; Towns
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delcode.delaware.gov/">
                    Delaware State Code
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//regulations.delaware.gov/">
                    State Regulations
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//firststeps.delaware.gov/">
                    Business First Steps
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box component="ul" sx={listStyle}>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//phonedirectory.delaware.gov">
                    Phone Directory
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/locationsdirectory/">
                    Locations Directory
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//publicmeetings.delaware.gov/">
                    Public Meetings
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//elections.delaware.gov">
                    Voting &amp; Elections
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/transparency">
                    Transparency
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//www.choosehealthde.com/Health-Insurance">
                    Delaware Marketplace
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/TaxCenter">
                    Tax Center
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//revenue.delaware.gov/pit_onlinefiling.shtml">
                    Personal Income Tax
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/help/privacy">
                    Privacy Policy
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/weatherpage">
                    Weather &amp; Travel
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box component="ul" sx={listStyle}>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/help/degov-contact.shtml">
                    Contact Us
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//corp.delaware.gov/">
                    Corporations
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//corp.delaware.gov/paytaxes.shtml">
                    Franchise Tax
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//grossreceiptstax.delaware.gov/grtpublic/">
                    Gross Receipts Tax
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//dorweb.revenue.delaware.gov/EDIOnline/EDIOnline.dll">
                    Withholding Tax
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/">
                    Delaware Topics
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/help/">
                    Help Center
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/apps">
                    Mobile Apps
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/subscribeemail">
                    E-mail / Text Alerts
                  </Link>
                </Box>
                <Box component="li" mb={0}>
                  <Link {...linkProps} href="//delaware.gov/topics/socialmedia">
                    Social Media
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3} sx={{ display: "flex", alignItems: "center" }}>
              <Image
                height={225}
                width={225}
                style={{ minWidth: "100%", minHeight: "300px", objectFit: "cover" }}
                src="https://dhss.delaware.gov/dhss/img/portal_footer_seal.png"
                alt="State Seal of Delaware"
                className="hidden-xs img-responsive"
              />
            </Grid>
          </Grid>
          <hr className={hr} />
          <Grid container spacing={4} sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", gap: 1, color: "white" }}>
                <Link
                  {...linkProps}
                  href="//www.facebook.com/delaware.gov"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span style={visuallyHidden}>Delaware Government Facebook</span>
                  <FacebookIcon fontSize="large" />
                </Link>
                <Link
                  {...linkProps}
                  href="//twitter.com/delaware_gov/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span style={visuallyHidden}>Delaware Government Twitter</span>
                  <TwitterIcon fontSize="large" />
                </Link>
                <Link
                  {...linkProps}
                  href="//www.youtube.com/user/DelawareGovernment"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span style={visuallyHidden}>Delaware Government Youtube</span>
                  <YouTubeIcon fontSize="large" />
                </Link>
                <Link
                  {...linkProps}
                  href="//www.instagram.com/delaware_gov/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span style={visuallyHidden}>Delaware Government Instagram</span>
                  <InstagramIcon fontSize="large" />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Slide>
  );
};

export default StateFooter;

"use client";

import Image from "next/legacy/image";
import { Box } from "@mui/material";
import delawareTextLogo from "public/images/delaware-logo-text.png";
import delawareWhiteLogo from "public/images/delaware-logo-white.png";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";

export const BrandBarHome = () => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", textDecoration: "none", flexDirection: "row" }}
      component="a"
      href="https://delaware.gov/"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() =>
        sendGaNavigationEvent({
          category: "Headers",
          action: "State Header Logo Click"
        })
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginRight: "5px"
        }}
      >
        <Image src={delawareWhiteLogo} height="40" width="40" alt="Delaware.gov Logo" />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "5px"
        }}
      >
        <Image src={delawareTextLogo} width="160" height="30" alt="Delaware.gov Logo Text" />
      </Box>
    </Box>
  );
};

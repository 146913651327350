"use client";

import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  AgenciesBrandButton,
  ContactBrandButton,
  NewsBrandButton,
  TopicsBrandButton
} from "./ExternalSiteButton";

export const ExternalSites = () => {
  const { breakpoints } = useTheme();

  return (
    <Box
      sx={{
        display: useMediaQuery(breakpoints.up("md")) ? "flex" : "none",
        ml: "auto",
        mr: "0px",
        px: "0",
        "> ul": {
          listStyle: "none",
          display: "flex",
          padding: 0,
          margin: 0
        },
        "ul li": {
          marginTop: "-2px",
          marginLeft: "14px"
        },
        "ul li:first-of-type": {
          marginLeft: 0
        }
      }}
    >
      <ul>
        <li>{useMediaQuery(breakpoints.up("xs")) && <AgenciesBrandButton />}</li>
        <li>{useMediaQuery(breakpoints.up("sm")) && <NewsBrandButton />}</li>
        <li>{useMediaQuery(breakpoints.up("sm")) && <TopicsBrandButton />}</li>
        <li>{useMediaQuery(breakpoints.up("md")) && <ContactBrandButton />}</li>
      </ul>
    </Box>
  );
};

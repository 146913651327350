import { ApolloLink } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import fetch from "cross-fetch";

const httpLink = new BatchHttpLink({
  uri: process.env.GRAPHQL_API ?? process.env.STORYBOOK_GRAPHQL_API,
  batchInterval: 100,
  batchDebounce: true,
  batchMax: 100,
  fetch: process.env.ENVIRONMENT === "test" ? fetch : undefined,
  credentials: process.env.ENVIRONMENT === "dev" ? "same-origin" : undefined
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 5
  }
});

// We need to fix something on the backend, we still don't know what it is.
// For the moment I'll leave the code, but I'll skip it below.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const customLink = new ApolloLink((operation, forward) => {
  // Extract the AST from the operation
  const operationAST = operation.query;

  // Find the operation definition node in the AST
  const definitionNode = operationAST.definitions.find((def) => def.kind === "OperationDefinition");

  // Get the operation name, if it exists
  const queryName =
    definitionNode && definitionNode.name ? definitionNode.name.value : "UnnamedQuery";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const variables: Record<string, any> = operation.variables ?? {};

  const uri = process.env.GRAPHQL_API ?? process.env.STORYBOOK_GRAPHQL_API;

  // Create a new URLSearchParams object
  const params = new URLSearchParams();
  // Dynamically append specific query variables as GET parameters
  params.append("queryName", queryName ?? "unknown");
  Object.entries(variables).forEach(([key, value]) => {
    if (key === "locationId") params.append(JSON.stringify(key), JSON.stringify(value));
  });
  // Construct the modified URI with query parameters
  const modifiedUri = `${uri ?? ""}?${params.toString()}`;
  // Set the modified URI back into the operation context
  operation.setContext(({ headers = {} }) => ({
    uri: modifiedUri,
    headers: {
      ...headers
    }
  }));

  // Forward the operation to the next link in the chain
  return forward(operation);
});

const authLink = setContext((operation_, { headers: linkHeaders }) => {
  let headers = { ...linkHeaders };
  if (process.env.GRAPHQL_API_AUTH_KEY || process.env.STORYBOOK_GQL_API_AUTH_KEY) {
    headers = {
      ...headers,
      "X-AUTH-TOKEN": process.env.GRAPHQL_API_AUTH_KEY || process.env.STORYBOOK_GQL_API_AUTH_KEY
    };
  }
  if (process.env.GRAPHQL_API_BASIC_AUTH || process.env.STORYBOOK_GQL_API_BASIC_AUTH) {
    headers = {
      ...headers,
      Authorization: process.env.GRAPHQL_API_BASIC_AUTH || process.env.STORYBOOK_GQL_API_BASIC_AUTH
    };
  }

  return {
    headers
  };
});

const apolloClientLink = ApolloLink.from([authLink, retryLink, httpLink]);

export default apolloClientLink;

"use client";

import { useEffect } from "react";
import { hotjar } from "react-hotjar";

type UseHotjar = {
  /*
   If false does not initialize Hotjar
   useful when we want to prevent initialization per page or on a specific page
  */
  initialize?: boolean;
  /*
    Hotjar ID
    @see https://help.hotjar.com/hc/en-us/articles/115011639927-What-is-the-Hotjar-Tracking-Code
  */
  id?: string;
  /*
    Hotjar Snippet Version
    @see https://help.hotjar.com/hc/en-us/articles/115011639927-What-is-the-Hotjar-Tracking-Code
  */
  sv?: string;
};

const useHotjar = ({ initialize = true, id, sv }: UseHotjar) => {
  useEffect(() => {
    if (!initialize) return;
    if (!id || !sv) return;
    const _id = parseInt(String(id));
    const _sv = parseInt(String(sv));
    if (isNaN(_id) || isNaN(_sv)) return;
    hotjar.initialize(_id, _sv, true);
  }, [id, initialize, sv]);
};

export const HotJarInitializer = () => {
  useHotjar({ id: process.env.HOTJAR_ID, sv: process.env.HOTJAR_SV });
  return <></>;
};

export default useHotjar;
